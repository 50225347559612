<template>
  <div class="wrapper">
    <AppHeader />
    <Suspense>
      <template #default>
        <ReadShow :id="id" />
      </template>
      <template #fallback>
        <Spinners />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Spinners from "@/components/Spinners/Spinners.vue";
const ReadShow = defineAsyncComponent(() =>
  import('@/components/Pages/ReadShow.vue')
)
export default {
  name: "ReadArticle",
  props: {
    id: String,
  },
  components: {
    AppHeader,
    AppFooter,
    ReadShow,
    Spinners,
    Suspense
  },
};
</script>

<style>
</style>